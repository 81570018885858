import React, { Component, useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

//MUI
import { LineChart, XAxis, YAxis, CartesianGrid, Legend, Line } from '@mui/x-charts';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// import {LineChart} from '@mui/x-charts';
import Box from '@mui/material/Box';
import PrintIcon from '@mui/icons-material/Print';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import { Typography } from '@mui/material';

const StarchGraph = props => {
    const navigate = useNavigate();
    const state = useSelector(s => s);
    const dispatch = useDispatch();
    const [FiveRecords, setFiveRecords] = useState(true);
    const [TenRecords, setTenRecords] = useState(false);
    const [FifteenRecords, setFifteenRecords] = useState(false);
    const [SelectedRecordValue, setSelectedRecordValue] = useState(5);
    const [SelectedRecords, setSelectedRecords] = useState([]);
    const [finalObj, setfinalObj] = useState(null);
    const [numberOfRecords, setnumberOfRecords] = useState([0, 1, 2, 3, 4, 5  ]);
    const [ModalOpen, setModalOpen] = useState(false);
    
    
    useEffect(() => {
      console.log("state-------            state-------            state-------            state-------            state-------            state-------            ", state);
      
        let temp = state.StartchGraphRecord.filter(f => f.plantID == props.plantID && !f.deleted);
        setSelectedRecords(temp)      
    }, [state]);
    
    useEffect(() => {
        let Obj = {
            RecordCount: SelectedRecordValue,
            DataSource: [],
            PlantData: null,
            StorageGraph: {
              viscocity: {
                title: "Holding Tank Viscosity",
                array: []
              },
              gelTemp: {
                title: "Holding Tank Gel Temperature",
                array: []
              },
              finTemp: {
                title: "Holding Tank Temperature",
                array: []
              }
            },
            DoserData: {
              viscocity: {
                title: "Doser Viscosity",
                array: []
              },
              gelTemp: {
                title: "Doser Gel Temperature",
                array: []
              },
              finTemp: {
                title: "Doser Temperature",
                array: []
              }
            },
            bBatchData: {
              viscocity: {
                title: "Batch Viscosity",
                array: []
              },
              gelTemp: {
                title: "Batch Gel Temperature",
                array: []
              },
              finTemp: {
                title: "Batch Finished Temperature",
                array: []
              }
            },
            DoserSFOne: {
              viscocity: {
                title: "Doser SF #1 Viscosity",
                array: []
              },
              gelTemp: {
                title: "Doser SF #1 Gel Temperature",
                array: []
              },
              finTemp: {
                title: "Doser SF #1 Temperature",
                array: []
              }
            },
            DoserSFTwo: {
              viscocity: {
                title: "Doser SF #2  Viscosity",
                array: []
              },
              gelTemp: {
                title: "Doser SF #2  Gel Temperature",
                array: []
              },
              finTemp: {
                title: "Doser SF #2 Temperature",
                array: []
              }
            },
            DoserDb: {
              viscocity: {
                title: "Doser DB Viscosity",
                array: []
              },
              gelTemp: {
                title: "Doser DB Gel Temperature",
                array: []
              },
              finTemp: {
                title: "Doser DB Temperature",
                array: []
              }
            },
            Chart: {
              Type: "Line",
              Title: "Monthly Sales Data",
              XAxis: {
                Title: "Date",
                DataField: "Date"
              }
            }
          }
          setfinalObj(null)
        if (SelectedRecords) {
          SelectedRecords.forEach(e => {
               if (e.location === 1) {
                    if (Obj.StorageGraph.gelTemp.array.length < SelectedRecordValue && e.type === 0) {
                        let temp = {
                            yVal: Obj.StorageGraph.gelTemp.array.length + 1,
                            xVal: e.value
                        }
                        Obj.StorageGraph.gelTemp.array.push(temp)
                    }
                    if (Obj.StorageGraph.viscocity.array.length < SelectedRecordValue && e.type === 1) {
                        let temp = {
                            yVal: Obj.StorageGraph.viscocity.array.length + 1,
                            xVal: e.value
                          }
                          Obj.StorageGraph.viscocity.array.push(temp)
                    }
                    if (Obj.StorageGraph.finTemp.array.length < SelectedRecordValue && e.type === 2) {
                        let temp = {
                            yVal: Obj.StorageGraph.finTemp.array.length + 1,
                            xVal: e.value
                          }
                        Obj.StorageGraph.finTemp.array.push(temp)
                    }
                    
               } 
               if (e.location === 0) {
                    if (Obj.bBatchData.viscocity.array.length < SelectedRecordValue && e.type === 1) {
                        let temp = {
                            yVal: Obj.bBatchData.viscocity.array.length + 1,
                            xVal: e.value
                        }
                        Obj.bBatchData.viscocity.array.push(temp)
                    }
                    if (Obj.bBatchData.gelTemp.array.length < SelectedRecordValue && e.type === 0) {
                        let temp = {
                            yVal: Obj.bBatchData.gelTemp.array.length + 1,
                            xVal: (((e.value - 32) * 5) / 9).toFixed(2)
                        }
                        Obj.bBatchData.gelTemp.array.push(temp)
                    }
                    if (Obj.bBatchData.finTemp.array.length < SelectedRecordValue && e.type === 2) {
                        let temp = {
                            yVal: Obj.bBatchData.finTemp.array.length + 1,
                            xVal: (((e.value - 32) * 5) / 9).toFixed(2)
                        }
                        Obj.bBatchData.finTemp.array.push(temp)
                    }
               } 
               if (e.location === 2 || e.location === 3 || e.location === 4) {
                    if (Obj.DoserData.viscocity.array.length < SelectedRecordValue && e.type === 1) {
                        let temp = {
                            yVal: Obj.DoserData.viscocity.array.length + 1,
                            xVal: e.value
                        }
                        Obj.DoserData.viscocity.array.push(temp)
                    }
                    if (Obj.DoserData.gelTemp.array.length < SelectedRecordValue && e.type === 0) {
                        let temp = {
                            yVal: Obj.DoserData.gelTemp.array.length + 1,
                            xVal: e.value
                        }
                        Obj.DoserData.gelTemp.array.push(temp)
                    }
                    if (Obj.DoserData.finTemp.array.length < SelectedRecordValue && e.type === 2) {
                        let temp = {
                            yVal: Obj.DoserData.finTemp.array.length + 1,
                            xVal: e.value
                        }
                        Obj.DoserData.finTemp.array.push(temp)
                    }
               } 
               if (e.location === 2) {
                    if (Obj.DoserSFOne.viscocity.array.length < SelectedRecordValue && e.type === 1) {
                        let temp = {
                            yVal: Obj.DoserSFOne.viscocity.array.length + 1,
                            xVal: e.value
                        }
                        Obj.DoserSFOne.viscocity.array.push(temp)
                    }
                    if (Obj.DoserSFOne.gelTemp.array.length < SelectedRecordValue && e.type === 0) {
                        let temp = {
                            yVal: Obj.DoserSFOne.gelTemp.array.length + 1,
                            xVal: e.value
                        }
                        Obj.DoserSFOne.gelTemp.array.push(temp)
                    }
                    if (Obj.DoserSFOne.finTemp.array.length < SelectedRecordValue && e.type === 2) {
                        let temp = {
                            yVal: Obj.DoserSFOne.finTemp.array.length + 1,
                            xVal: e.value
                        }
                        Obj.DoserSFOne.finTemp.array.push(temp)
                    }
               } 
               if (e.location === 3) {
                    if (Obj.DoserSFTwo.viscocity.array.length < SelectedRecordValue && e.type === 1) {
                        let temp = {
                            yVal: Obj.DoserSFTwo.viscocity.array.length + 1,
                            xVal: e.value
                        }
                        Obj.DoserSFTwo.viscocity.array.push(temp)
                    }
                    if (Obj.DoserSFTwo.gelTemp.array.length < SelectedRecordValue && e.type === 0) {
                        let temp = {
                            yVal: Obj.DoserSFTwo.gelTemp.array.length + 1,
                            xVal: e.value
                        }
                        Obj.DoserSFTwo.gelTemp.array.push(temp)
                    }
                    if (Obj.DoserSFTwo.finTemp.array.length < SelectedRecordValue && e.type === 2) {
                        let temp = {
                            yVal: Obj.DoserSFTwo.finTemp.array.length + 1,
                            xVal: e.value
                        }
                        Obj.DoserSFTwo.finTemp.array.push(temp)
                    }
               } 
               if (e.location === 4) {
                    if (Obj.DoserDb.viscocity.array.length < SelectedRecordValue && e.type === 1) {
                        let temp = {
                            yVal: Obj.DoserDb.viscocity.array.length + 1,
                            xVal: e.value
                        }
                        Obj.DoserDb.viscocity.array.push(temp)
                    }
                    if (Obj.DoserDb.gelTemp.array.length < SelectedRecordValue && e.type === 0) {
                        let temp = {
                            yVal: Obj.DoserDb.gelTemp.array.length + 1,
                            xVal: e.value
                        }
                        Obj.DoserDb.gelTemp.array.push(temp)
                    }
                    if (Obj.DoserDb.finTemp.array.length < SelectedRecordValue && e.type === 2) {
                        let temp = {
                            yVal: Obj.DoserDb.finTemp.array.length + 1,
                            xVal: e.value
                        }
                        Obj.DoserDb.finTemp.array.push(temp)
                    }
               } 
            });
        }
        
        setfinalObj(Obj)

        console.log('Obj', Obj)

        if (SelectedRecordValue === 5) {
          setnumberOfRecords([0, 1, 2, 3, 4, 5  ])
        }
        if (SelectedRecordValue === 10) {
          setnumberOfRecords([0,1,2,3,4,5,6,7,8,9,10])
        }
        if (SelectedRecordValue === 15) {
          setnumberOfRecords([0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15])
        }
        


    }, [SelectedRecordValue,SelectedRecords]);

    const handleClick = (value) => {
        if (value === 5) {
            setFiveRecords(true)
            setTenRecords(false)
            setFifteenRecords(false)
        }
        if (value === 10) {
            setFiveRecords(false)
            setTenRecords(true)
            setFifteenRecords(false)
        }
        if (value === 15) {
            setFiveRecords(false)
            setTenRecords(false)
            setFifteenRecords(true)
        }
        setSelectedRecordValue(value)
    };
    const handlePrint = () => {
        // const queryString = new URLSearchParams({ count: SelectedRecordValue }).toString();
        // navigate(`/PlantStarchCheckGraph/${props.plantID}?${queryString} `)
        setModalOpen(true)
    }
    

      function extractXValues(data) {
          const xValues = data.map(item => item.xVal);
          xValues.unshift(null);
          return xValues;
      }
      const renderCustomMark = ({ x, y, value }) => {
        return (
          <g>
            <circle cx={x} cy={y} r={5} fill="blue" />
            <text x={x} y={y - 10} textAnchor="middle" fill="black">
              {y}
            </text>
          </g>
        );
      };
    return (<>
        <Card style={{ position: 'relative'}} >
            <CardHeader title="Graph of Starch Report" />
            <div style={{ position: "absolute", right: '20px' , top: '20px'}}>
            <Button
                variant="contained"
                startIcon={<PrintIcon />}
                onClick={handlePrint}
            >
                Print
            </Button>
            </div>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '12px'}}>
                            Data Range:
                            <Chip onClick={ () => handleClick(5)} label="5 Records" color="primary" variant={FiveRecords ? 'filled' : 'outlined'} />
                            <Chip onClick={ () => handleClick(10)} label="10 Records" color="primary" variant={TenRecords ? 'filled' : 'outlined'} />
                            <Chip onClick={ () => handleClick(15)} label="15 Records" color="primary" variant={FifteenRecords ? 'filled' : 'outlined'} />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        { finalObj ?
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                  <Typography variant="h5">Mixer Chart</Typography>
                                    <Box sx={{ width: '100%', maxWidth: 500 }}>
                                    {/* <LineChart
                                      xAxis={[{ data: numberOfRecords }]}
                                      series={[
                                        {
                                          label: 'Viscosity',
                                          data: extractXValues(finalObj.DoserSFOne.viscocity.array),
                                          curve: 'linear',
                                          experimentalMarkRendering: true,
                                          renderCustomMark, // Use the custom mark rendering function
                                        },
                                        {
                                          label: 'Gel Temp (°C)',
                                          data: extractXValues(finalObj.bBatchData.gelTemp.array),
                                          curve: 'linear',
                                          experimentalMarkRendering: true,
                                          renderCustomMark, // Use the custom mark rendering function
                                        },
                                        {
                                          label: 'Finished Temp (°C)',
                                          data: extractXValues(finalObj.bBatchData.finTemp.array),
                                          curve: 'linear',
                                          experimentalMarkRendering: true,
                                          renderCustomMark, // Use the custom mark rendering function
                                        },
                                      ]}
                                      width={SelectedRecordValue === 5 ? 499 : SelectedRecordValue === 10 ? 600 : 900}
                                      height={300}
                                    >
                                    </LineChart> */}
                                      <LineChart
                                        xAxis={[{ data: numberOfRecords }]}
                                        series={[
                                          { curve: "linear", data: extractXValues(finalObj.bBatchData.viscocity.array) ,label: 'Viscosity' },
                                          { curve: "linear", data: extractXValues(finalObj.bBatchData.gelTemp.array), label: 'Gel Temp (°C)' },
                                          { curve: "linear", data: extractXValues(finalObj.bBatchData.finTemp.array), label: 'finished Temp (°C)' },
                                          ]}

                                        width={SelectedRecordValue === 5 ? 499 : SelectedRecordValue === 10 ? 600 : 900}
                                        height={300}
                                        >
                                        </LineChart>
                                    </Box>
                                </Grid>
                                <Grid item xs={SelectedRecordValue === 5 ? 4: SelectedRecordValue === 10 ? 6 : 12}>
                                  <Typography variant="h5">Doser Viscosity </Typography>
                                    <Box sx={{ width: '100%', maxWidth: 500 }}>
                                      <LineChart
                                        xAxis={[{ data: numberOfRecords }]}
                                        series={[
                                          { curve: "linear", data: extractXValues(finalObj.DoserSFOne.viscocity.array) ,label: 'SF #1' },
                                          { curve: "linear", data: extractXValues(finalObj.DoserSFTwo.viscocity.array), label: 'SF #2' },
                                          { curve: "linear", data: extractXValues(finalObj.DoserDb.viscocity.array), label: 'DB' },
                                          ]}
                                        width={SelectedRecordValue === 5 ? 450 : SelectedRecordValue === 10 ? 600 : 900}
                                        height={300}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={SelectedRecordValue === 5 ? 4: SelectedRecordValue === 10 ? 6 : 12}>
                                  <Typography variant="h5">Doser Gel Temperature</Typography>
                                    <Box sx={{ width: '100%', maxWidth: 500 }}>
                                      <LineChart
                                        xAxis={[{ data: numberOfRecords }]}
                                        series={[
                                          { curve: "linear", data: extractXValues(finalObj.DoserSFOne.gelTemp.array) ,label: 'SF #1' },
                                          { curve: "linear", data: extractXValues(finalObj.DoserSFTwo.gelTemp.array), label: 'SF #2' },
                                          { curve: "linear", data: extractXValues(finalObj.DoserDb.gelTemp.array), label: 'DB' },
                                          ]}
                                        width={SelectedRecordValue === 5 ? 450 : SelectedRecordValue === 10 ? 600 : 900}
                                        height={300}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={SelectedRecordValue === 5 ? 4: SelectedRecordValue === 10 ? 6 : 12}>
                                  <Typography variant="h5">Doser Temperature</Typography>
                                    <Box sx={{ width: '100%', maxWidth: 500 }}>
                                      <LineChart
                                        xAxis={[{ data: numberOfRecords }]}
                                        series={[
                                          { curve: "linear", data: extractXValues(finalObj.DoserSFOne.finTemp.array) ,label: 'SF #1' },
                                          { curve: "linear", data: extractXValues(finalObj.DoserSFTwo.finTemp.array), label: 'SF #2' },
                                          { curve: "linear", data: extractXValues(finalObj.DoserDb.finTemp.array), label: 'DB' },
                                          ]}
                                        width={SelectedRecordValue === 5 ? 450 : SelectedRecordValue === 10 ? 600 : 900}
                                        height={300}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={SelectedRecordValue === 5 ? 4: SelectedRecordValue === 10 ? 6 : 12}>
                                  <Typography variant="h5">Storage Tank Viscosity </Typography>
                                  <Box sx={{ width: '100%', maxWidth: 500 }}>
                                    <LineChart
                                      xAxis={[{ data: numberOfRecords }]}
                                      series={[
                                        { curve: "linear", data: extractXValues(finalObj.StorageGraph.viscocity.array) ,label: 'Viscosity ' },
                                        ]}
                                      width={SelectedRecordValue === 5 ? 450 : SelectedRecordValue === 10 ? 600 : 900}
                                      height={300}
                                      />
                                  </Box>
                                </Grid>
                                <Grid item xs={SelectedRecordValue === 5 ? 4: SelectedRecordValue === 10 ? 6 : 12}>
                                  <Typography variant="h5">Storage Tank Gel Temperature</Typography>
                                  <Box sx={{ width: '100%', maxWidth: 500 }}>
                                    <LineChart
                                      xAxis={[{ data: numberOfRecords }]}
                                      series={[
                                        { curve: "linear", data: extractXValues(finalObj.StorageGraph.gelTemp.array), label: 'Gel Temperature ' },
                                        ]}
                                      width={SelectedRecordValue === 5 ? 450 : SelectedRecordValue === 10 ? 600 : 900}
                                      height={300}
                                      />
                                  </Box>
                                </Grid>
                                <Grid item xs={SelectedRecordValue === 5 ? 4: SelectedRecordValue === 10 ? 6 : 12}>
                                  <Typography variant="h5">Storage Tank Temperature</Typography>
                                  <Box sx={{ width: '100%', maxWidth: 500 }}>
                                    <LineChart
                                      xAxis={[{ data: numberOfRecords }]}
                                      series={[
                                        { curve: "linear", data: extractXValues(finalObj.StorageGraph.finTemp.array), label: 'finished Temperature' },
                                        ]}
                                      width={SelectedRecordValue === 5 ? 450 : SelectedRecordValue === 10 ? 600 : 900}
                                      height={300}
                                      />
                                  </Box>
                                </Grid>
                                {/* <Grid item xs={SelectedRecordValue === 5 ? 4: SelectedRecordValue === 10 ? 6 : 12}>
                                  <Typography variant="h5">Storage</Typography>
                                  <Box sx={{ width: '100%', maxWidth: 500 }}>
                                    <LineChart
                                      xAxis={[{ data: numberOfRecords }]}
                                      series={[
                                        { curve: "linear", data: extractXValues(finalObj.StorageGraph.viscocity.array) ,label: 'Viscocity' },
                                        { curve: "linear", data: extractXValues(finalObj.StorageGraph.gelTemp.array), label: 'Gel Temp ' },
                                        { curve: "linear", data: extractXValues(finalObj.StorageGraph.finTemp.array), label: 'finished Temp' },
                                        ]}
                                      width={SelectedRecordValue === 5 ? 450 : SelectedRecordValue === 10 ? 600 : 900}
                                      height={300}
                                      />
                                  </Box>
                                </Grid> */}
                            </Grid>: <Grid container spacing={3}>
                                <Grid item xs>
                                Loading.....
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </CardContent>
        </Card>;
        <PrintOptions plantID={props.plantID} SelectedRecordValue={SelectedRecordValue} modalOpen={ModalOpen} closeModal={() => setModalOpen(false)}
        />
    </>
    );
}
export default StarchGraph;

export const PrintOptions = props => {
  const [FiveRecords, setFiveRecords] = useState(false);
  const [TenRecords, setTenRecords] = useState(false);
  const [FifteenRecords, setFifteenRecords] = useState(false);
  const [SelectedRecordValue, setSelectedRecordValue] = useState(0);
  const navigate = useNavigate();
  const handleClick = (value) => {
    if (value === 5) {
        setFiveRecords(true)
        setTenRecords(false)
        setFifteenRecords(false)
    }
    if (value === 10) {
        setFiveRecords(false)
        setTenRecords(true)
        setFifteenRecords(false)
    }
    if (value === 15) {
        setFiveRecords(false)
        setTenRecords(false)
        setFifteenRecords(true)
    }
    setSelectedRecordValue(value)
};
  const PrintNow = () => {
    const queryString = new URLSearchParams({ count: SelectedRecordValue }).toString();
    navigate(`/PlantStarchCheckGraph/${props.plantID}?${queryString} `)
  };
  return (
      <Dialog fullWidth maxWidth={"sm"} open={props.modalOpen} >
        <DialogTitle id="alert-dialog-title">Print records options</DialogTitle>
          <DialogContent>
              <Grid container spacing={3}>
                  <Grid item xs={12} sx={{ marginRight: '20px' }} >
                  <div style={{ display: 'flex', alignItems: 'center', gap: '12px'}}>
                            Data Range:
                            <Chip onClick={ () => handleClick(5)} label="5 Records" color="primary" variant={FiveRecords ? 'filled' : 'outlined'} />
                            <Chip onClick={ () => handleClick(10)} label="10 Records" color="primary" variant={TenRecords ? 'filled' : 'outlined'} />
                            <Chip onClick={ () => handleClick(15)} label="15 Records" color="primary" variant={FifteenRecords ? 'filled' : 'outlined'} />
                        </div>
                  </Grid>
              </Grid>
          </DialogContent>
          <DialogActions>
              <Button onClick={props.closeModal}>Close</Button>
              <Button disabled={SelectedRecordValue === 0 } onClick={PrintNow}>Print Now</Button>
          </DialogActions>
      </Dialog >
  );
};