import Constants from '../../constants';
//Libraries
import React, { useEffect, useContext, useState } from 'react';
import { createSelector } from 'reselect';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import  {useNavigate, useParams, useLocation } from 'react-router-dom';
import ReportViewer from '../Report/ReportViewer';
import { SelVisitByID,SelInventoryByVisitID,SelMeasuresByVisitID,FormatLocation,SelSKItemsByVisitID } from '../../selectors/VisitSelectors';
import { useReportUser,usePlantDataFromVisitID, useVisitData, useReportName } from '../../misc/UseFuncs';
import { getStore } from '../../core';
const PlantsSel = state=>state.Plants;
const PlantByID =createSelector(PlantsSel,(state,pID)=>pID,
(plants,pID)=>{
    return plants.find(p=>p.id===pID);
}
)
const StarchChecksGraphPrint = props => {
  const nagivate = useNavigate();
    const params = useParams();
    const PlantData = useSelector(s=>PlantByID(s,params.PlantID));
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const jsonData = queryParams.get('count');
    const count = JSON.parse(jsonData);

    const state = useSelector(s => s);
    const dispatch = useDispatch();
    const [FiveRecords, setFiveRecords] = useState(true);
    const [TenRecords, setTenRecords] = useState(false);
    const [FifteenRecords, setFifteenRecords] = useState(false);
    const [SelectedRecordValue, setSelectedRecordValue] = useState(count);
    const [SelectedRecords, setSelectedRecords] = useState([]);
    const [finalObj, setfinalObj] = useState(null);

    
    useEffect(() => {
        console.log('tate.--tate.--tate.--tate.--tate.--tate.--tate.--tate.--tate.--tate.--tate.--tate.--tate.-- state.', state)
        if (state.StartchGraphRecord) {
          let temp = state.StartchGraphRecord.filter(f => f.plantID == params.PlantID && !f.deleted);
            if (temp) {
                setSelectedRecords(temp)        
            }
            
        }
              
    }, [state]);
    
    useEffect(() => {
      let Obj = {
        DataSource: {
          RecordCount: SelectedRecordValue,
      },
        PlantData: PlantData,
        StorageGraph: {
          viscocity: {
            title: "Holding Tank Viscosity",
            array: []
          },
          gelTemp: {
            title: "Holding Tank Gel Temperature",
            array: []
          },
          finTemp: {
            title: "Holding Tank Temperature",
            array: []
          }
        },
        DoserData: {
          viscocity: {
            title: "Doser Viscosity",
            array: []
          },
          gelTemp: {
            title: "Doser Gel Temperature",
            array: []
          },
          finTemp: {
            title: "Doser Temperature",
            array: []
          }
        },
        bBatchData: {
          viscocity: {
            title: "Batch Viscosity",
            array: []
          },
          gelTemp: {
            title: "Batch Gel Temperature",
            array: []
          },
          finTemp: {
            title: "Batch Finished Temperature",
            array: []
          }
        },
        DoserSFOne: {
          viscocity: {
            title: "Doser SF #1 Viscosity",
            array: []
          },
          gelTemp: {
            title: "Doser SF #1 Gel Temperature",
            array: []
          },
          finTemp: {
            title: "Doser SF #1 Temperature",
            array: []
          }
        },
        DoserSFTwo: {
          viscocity: {
            title: "Doser SF #2  Viscosity",
            array: []
          },
          gelTemp: {
            title: "Doser SF #2  Gel Temperature",
            array: []
          },
          finTemp: {
            title: "Doser SF #2 Temperature",
            array: []
          }
        },
        DoserDb: {
          viscocity: {
            title: "Doser DB Viscosity",
            array: []
          },
          gelTemp: {
            title: "Doser DB Gel Temperature",
            array: []
          },
          finTemp: {
            title: "Doser DB Temperature",
            array: []
          }
        },
        Chart: {
          Type: "Line",
          Title: "Monthly Sales Data",
          XAxis: {
            Title: "Date",
            DataField: "Date"
          }
        }
      }
      if (SelectedRecords) {
        SelectedRecords.forEach(e => {
             if (e.location === 1) {
                  if (Obj.StorageGraph.gelTemp.array.length < SelectedRecordValue && e.type === 0) {
                      let temp = {
                          yVal: Obj.StorageGraph.gelTemp.array.length + 1,
                          xVal: e.value
                      }
                      Obj.StorageGraph.gelTemp.array.push(temp)
                  }
                  if (Obj.StorageGraph.viscocity.array.length < SelectedRecordValue && e.type === 1) {
                      let temp = {
                          yVal: Obj.StorageGraph.viscocity.array.length + 1,
                          xVal: e.value
                        }
                        Obj.StorageGraph.viscocity.array.push(temp)
                  }
                  if (Obj.StorageGraph.finTemp.array.length < SelectedRecordValue && e.type === 2) {
                      let temp = {
                          yVal: Obj.StorageGraph.finTemp.array.length + 1,
                          xVal: e.value
                        }
                      Obj.StorageGraph.finTemp.array.push(temp)
                  }
                  
             } 
             if (e.location === 0) {
                  if (Obj.bBatchData.viscocity.array.length < SelectedRecordValue && e.type === 1) {
                      let temp = {
                          yVal: Obj.bBatchData.viscocity.array.length + 1,
                          xVal: e.value
                      }
                      Obj.bBatchData.viscocity.array.push(temp)
                  }
                  if (Obj.bBatchData.gelTemp.array.length < SelectedRecordValue && e.type === 0) {
                      let temp = {
                          yVal: Obj.bBatchData.gelTemp.array.length + 1,
                          xVal: (((e.value - 32) * 5) / 9).toFixed(2)
                      }
                      Obj.bBatchData.gelTemp.array.push(temp)
                  }
                  if (Obj.bBatchData.finTemp.array.length < SelectedRecordValue && e.type === 2) {
                      let temp = {
                          yVal: Obj.bBatchData.finTemp.array.length + 1,
                          xVal: (((e.value - 32) * 5) / 9).toFixed(2)
                      }
                      Obj.bBatchData.finTemp.array.push(temp)
                  }
             } 
             if (e.location === 2 || e.location === 3 || e.location === 4) {
                  if (Obj.DoserData.viscocity.array.length < SelectedRecordValue && e.type === 1) {
                      let temp = {
                          yVal: Obj.DoserData.viscocity.array.length + 1,
                          xVal: e.value
                      }
                      Obj.DoserData.viscocity.array.push(temp)
                  }
                  if (Obj.DoserData.gelTemp.array.length < SelectedRecordValue && e.type === 0) {
                      let temp = {
                          yVal: Obj.DoserData.gelTemp.array.length + 1,
                          xVal: e.value
                      }
                      Obj.DoserData.gelTemp.array.push(temp)
                  }
                  if (Obj.DoserData.finTemp.array.length < SelectedRecordValue && e.type === 2) {
                      let temp = {
                          yVal: Obj.DoserData.finTemp.array.length + 1,
                          xVal: e.value
                      }
                      Obj.DoserData.finTemp.array.push(temp)
                  }
             } 
             if (e.location === 2) {
                  if (Obj.DoserSFOne.viscocity.array.length < SelectedRecordValue && e.type === 1) {
                      let temp = {
                          yVal: Obj.DoserSFOne.viscocity.array.length + 1,
                          xVal: e.value
                      }
                      Obj.DoserSFOne.viscocity.array.push(temp)
                  }
                  if (Obj.DoserSFOne.gelTemp.array.length < SelectedRecordValue && e.type === 0) {
                      let temp = {
                          yVal: Obj.DoserSFOne.gelTemp.array.length + 1,
                          xVal: e.value
                      }
                      Obj.DoserSFOne.gelTemp.array.push(temp)
                  }
                  if (Obj.DoserSFOne.finTemp.array.length < SelectedRecordValue && e.type === 2) {
                      let temp = {
                          yVal: Obj.DoserSFOne.finTemp.array.length + 1,
                          xVal: e.value
                      }
                      Obj.DoserSFOne.finTemp.array.push(temp)
                  }
             } 
             if (e.location === 3) {
                  if (Obj.DoserSFTwo.viscocity.array.length < SelectedRecordValue && e.type === 1) {
                      let temp = {
                          yVal: Obj.DoserSFTwo.viscocity.array.length + 1,
                          xVal: e.value
                      }
                      Obj.DoserSFTwo.viscocity.array.push(temp)
                  }
                  if (Obj.DoserSFTwo.gelTemp.array.length < SelectedRecordValue && e.type === 0) {
                      let temp = {
                          yVal: Obj.DoserSFTwo.gelTemp.array.length + 1,
                          xVal: e.value
                      }
                      Obj.DoserSFTwo.gelTemp.array.push(temp)
                  }
                  if (Obj.DoserSFTwo.finTemp.array.length < SelectedRecordValue && e.type === 2) {
                      let temp = {
                          yVal: Obj.DoserSFTwo.finTemp.array.length + 1,
                          xVal: e.value
                      }
                      Obj.DoserSFTwo.finTemp.array.push(temp)
                  }
             } 
             if (e.location === 4) {
                  if (Obj.DoserDb.viscocity.array.length < SelectedRecordValue && e.type === 1) {
                      let temp = {
                          yVal: Obj.DoserDb.viscocity.array.length + 1,
                          xVal: e.value
                      }
                      Obj.DoserDb.viscocity.array.push(temp)
                  }
                  if (Obj.DoserDb.gelTemp.array.length < SelectedRecordValue && e.type === 0) {
                      let temp = {
                          yVal: Obj.DoserDb.gelTemp.array.length + 1,
                          xVal: e.value
                      }
                      Obj.DoserDb.gelTemp.array.push(temp)
                  }
                  if (Obj.DoserDb.finTemp.array.length < SelectedRecordValue && e.type === 2) {
                      let temp = {
                          yVal: Obj.DoserDb.finTemp.array.length + 1,
                          xVal: e.value
                      }
                      Obj.DoserDb.finTemp.array.push(temp)
                  }
             } 
          });
      }
        
        setfinalObj(Obj)
        console.log('FINAL Obj', Obj)

    }, [SelectedRecords]);


    const Data ={ StarchGraphReport:JSON.stringify(finalObj)};
    const reportName=useReportName("Starch Graph Report", PlantData?.name, null);
    let reportData = {Loaded:true,Data:Data,ReportName:reportName,EmailSubject:'Theraml Images '+PlantData?.name};
    
    const handleClose = () => {
      nagivate('/PlantEdit/' + params.PlantID + "/" + 7);
    }
    if (finalObj === null) {
       return <div>Loading...</div>;
    } 
    return <ReportViewer type="StarchGraphReport" name="StarchGraphReport" printData={reportData} 
       onClose={handleClose}  />;
    
}
export default StarchChecksGraphPrint;